//import { initializeApp } from "firebase/app";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {

    apiKey: "AIzaSyCJWFBfzo_M7xfUBmmVtZHgn_9tTppuAow",
  
    authDomain: "yesed-login-9b5b3.firebaseapp.com",
  
    projectId: "yesed-login-9b5b3",
  
    storageBucket: "yesed-login-9b5b3.appspot.com",
  
    messagingSenderId: "996168317539",
  
    appId: "1:996168317539:web:84a49c74495e3db54a1656"
  
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;